import React from 'react'
import {
  Img,
  ImgWrapper,
  CaseIntro,
  InfoRow,
  InfoWrapper,
  ImgGroup,
  ImageItem,
  Text,
  TextWrapper,
  CaseWrapper
} from './CaseElements';
import {CaseObj} from './Data';
import { HeroBg, HeroContainer, ImgBg } from '../HeroSection/HeroElements';
import Grids from '../Grids';
import img from '../../images/heroImage-case.png';
import imgMobile from '../../images/heroImage-caseMobile.jpg';
import { Col, Container, Row } from 'react-bootstrap'

function CaseInfo (props) {
  const items = CaseObj.case
  return (
    <InfoWrapper>
      <HeroContainer style={{marginTop: 0}}>
        <HeroBg>
          <ImgBg class='d-sm-none' src={img} />
          <ImgBg class='d-none d-sm-block' src={imgMobile} />
        </HeroBg>
      </HeroContainer>
      <Grids style={{top: '80px'}}/>
        <InfoRow>
          <ImgWrapper>
            <Img src={CaseObj.introduction.img}/>
          </ImgWrapper>
          <TextWrapper>
            <Text className='thin-text'>{CaseObj.introduction.sectionOne}</Text>
            <Text className='thin-text'>{CaseObj.introduction.sectionTwo}</Text>
          </TextWrapper>
        </InfoRow>
          {[...Array(items.length)].map((_, index) =>
            <CaseWrapper>
              <ImgGroup>
                {[...Array(items[index].img.length)].map((_, k) =>
                  <ImageItem src={items[index].img[k]} />
                )}
              </ImgGroup>
              <Row fluid className='container-fluid visual-grid-container'>
                <Col sm={12} md={2} className="d-none d-md-block"/>
                <CaseIntro sm={12} md={8}>
                  <p className='regular-text'>{items[index].name}</p>
                  {[...Array(items[index].info.length)].map((_, k) =>
                    <p className='thin-text'>{items[index].info[k]}</p>
                  )}
                </CaseIntro>
                <Col sm={12} md={2} className="d-none d-md-block"/>
              </Row>
            </CaseWrapper>
          )}
      </InfoWrapper>
  )
}

export default CaseInfo
