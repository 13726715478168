import video from '../../images/LOCSlides/video.mp4'
import img1 from '../../images/LOCSlides/swiper_0.png';
import img2 from '../../images/LOCSlides/编组 27.png'
export const LOCObj = {
  items: [
    {
      name: '药剂盒',
      description: '毫厘科技开发的微试剂系统可以使⽤国标化学分析⽅法并使⽤微升级的试剂来完成单次检测，试剂与芯⽚⼀起作为试剂盒耗材可以使⽤数千次以上，使⽤结束后可以很便利的更换。多种⽔质参数如氨氮、总磷、余氯、亚硝酸盐等都可以在微试剂平台上进⾏连续在线监测。',
      img: video,
    }, {
      name: '芯⽚实验室',
      description: '芯⽚实验室（Lab-On-a-Chip, LOC)是⼀种可以把多种实验室功能集成到⼀块厘⽶尺⼨的“芯⽚”上来实现⾃动\n' +
        '化⽣物或化学样品分析的技术。LOC技术可以处理极端少量（微升级别）的样品来同时进⾏多项指标的检\n' +
        '测，因此LOC也被成为微量全分析系统 （micro total analysis system , μTAS），同时也是微机电系统\n' +
        '（microelectromechanical system, MEMS）家族重要分⽀',
      img: img1,
      link: 'https://www.nature.com/subjects/lab-on-a-chip',
    }, {
      name: '微流通道上实现离⼦选择和光学测量',
      description: '毫厘科技依托LOC平台颠覆了组多重要离⼦的快速测定。毫厘科技开发了包括离⼦载体敏感膜\n' +
        '和离⼦交换聚合物在内的多种新型材料来对⽬标离⼦如氨氮、硝酸盐或有机物进⾏分离和鉴\n' +
        '定，有针对性的通过电化学技术或光谱技术在不使⽤任何化学的情况下来完成氨氮、硝酸盐，\n' +
        '并以此来快速确定样品中总氮的含量。',
      img: img2,
    }

  ]
}
