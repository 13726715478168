import React from 'react';
import Grids from '../components/Grids';
import HeroSection from '../components/HeroSection';
import img from '../images/heroimage-device.jpeg';
import MonitorDetailInfo from '../components/MonitorDetailInfo';
import '../styles/TextStyle.css'

function MonitorDetail (props) {
  return (
    <div>
      <MonitorDetailInfo {...props}/>
    </div>
  )
}

export default MonitorDetail
