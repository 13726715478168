import React from 'react'
import {
  SectionWrapper,
  BulletWrapper,
  ButtonWrapper,
  ContentWrapper,
  DataItem,
  DataSection,
  Image,
  ImageWrapper,
  TextWrapper,
  Headline
} from './StationElements';
import { ButtonLink } from '../ButtonElement';
import {StationObj} from './Data'

const StationInfo = () => {
  const items = StationObj.items
  return (
    <ContentWrapper>
      {[...Array(items.length)].map((_, index) =>
        <SectionWrapper>
          <Headline className='big-subtitle light-color'>{items[index].name}</Headline>
          <TextWrapper className={index === 1 ? 'flex-order-2' : ''}>
            <p className='thin-text light-color small-text'>{items[index].description}</p>
            <span className='thin-text light-color small-text'>优势</span>
            <BulletWrapper>
              {[...Array(items[index].advantage.length)].map((_, k) =>
                <li className='thin-text light-color small-text'>{items[index].advantage[k]}</li>
              )}
            </BulletWrapper>
            <span className='thin-text light-color small-text'>应用场景</span>
            <BulletWrapper>
              {[...Array(items[index].settings.length)].map((_, k) =>
                <li className='thin-text light-color small-text'>{items[index].settings[k]}</li>
              )}
            </BulletWrapper>
            <DataSection>
              {[...Array(items[index].info.length)].map((_, k) =>
                <DataItem>
                  <p className='light-text light-color'>{items[index].info[k].name}</p>
                  <p className='light-text light-color'>{items[index].info[k].data}</p>
                </DataItem>
              )}
            </DataSection>
            <ButtonWrapper className={index === 1 ? 'flex-order-3' : ''}>
              <ButtonLink to={items[index].link} target="_blank">产品手册</ButtonLink>
            </ButtonWrapper>
          </TextWrapper>
          <ImageWrapper className={index === 1 ? 'flex-order-1' : ''}>
            <Image src={items[index].img} />
          </ImageWrapper>
        </SectionWrapper>
      )}
    </ContentWrapper>
  )
}

export default StationInfo
