import styled from 'styled-components';
import {Col} from 'react-bootstrap';

export const TextWrapper = styled(Col)`
  padding: 80px 0;
`
export const Description = styled(Col)`
  display: flex;
  padding: 0;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const DescriptionSection = styled(Col)`
  padding-left: 0;
  padding-right: 30px;
  p {
    padding: 0;
    margin-top: 20px;
  }
  @media screen and (min-width: 992px) {
    padding-right: 80px;
  }
  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
`
