import styled from 'styled-components'
import {NavLink, Link} from 'react-router-dom'


export const Nav = styled.nav`
  background: none;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  top: 0;
  z-index: 10;
  
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`

export const NavbarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0;
`

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
`

export const NavLogoImg = styled.img`
  width: 120px;
  height: 18.58px;
`

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1000px) {
    justify-content: flex-end;
    padding: 0;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    transform: translates(-100%, 60%);
    color: #fff;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  padding: 0;
  
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
  cursor: pointer;
`

export const NavLinks = styled((NavLink))`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 60px;
  height: 100%;
  cursor: pointer;
  
  span {
    border-bottom: 2px solid transparent;
    margin-top: 7px;
    padding-bottom: 5px;
  }

  &.active span{
    border-bottom: 2px solid #14a1b3;
    transition: all .2s ease-in-out;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    opacity: .5;
    text-decoration: none;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const NavBtnLink = styled(Link)`
  white-space: normal;
  padding: 5px 12px;
  color: #fff;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    opacity: .5;
    text-decoration: none;
  }
`

export const HtmlLink = styled.a`
  white-space: normal;
  padding: 5px 12px;
  color: #fff;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    opacity: .5;
    text-decoration: none;
  }
`

