import styled from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
  margin-top: -80px;
`

export const HeroBg = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 150px;
  padding: 0;
`

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 86px;
  font-family: 'PingFangSC-Semibold', 'NotoSansSC-Bold', monospace;
  margin: 0;  
  text-align: left;
  letter-spacing: 0;
  line-height: 100px;
  @media screen and (max-width: 1400px) {
    font-size: 65px;
    line-height: 82px;
  }
  
  @media screen and (max-width: 992px) {
    font-size: 40px;
    line-height: 46px;
  }  
  
  @media screen and (max-width: 768px) {
   font-size: 32px;
    line-height: 40px;
  }
`

export const HeroP = styled.p`
  margin-top: 20px;
  color: #fff;
  font-size: 36px;
  font-weight: 200;
  font-family: 'PingFangSC-Thin', 'NotoSansSC-Thin', monospace;
  letter-spacing: 0;
  line-height: 54px;
  text-align: left;
  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media screen and (max-width: 1400px) {
    font-size: 22px;
    line-height: 30px;
  }
`
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`
