import EpochLite from '../../images/stations/epochLite.png';
import EpoachPro from '../../images/stations/epoachPro.png';
import EpoachPi from '../../images/stations/epoachPi.png';
import EpochLitePdf from '../../pdf/EpochLite产品手册_2021_v2_1_fmt_pdf.pdf';
import EpoachPiPdf from '../../pdf/EpochPi产品手册_2021_v2_1_fmt_pdf.pdf';
import EpoachProPdf from '../../pdf/EpochPro产品手册_2021_v2_1_fmt_pdf.pdf';

export const StationObj = {
  items:[
    {
      id:'EpochLite',
      name: 'Epoch Lite',
      img: EpochLite,
      link: EpochLitePdf,
      description: 'Epcoh Lite是毫厘科技开发的体积最⼩的超微型⽔质监测站。Epoch Lite采⽤壁挂式安装，⽆需搭建\n' +
        '安装环境，只需要将⽔路和供电接⼊即可快速投⼊使⽤。Epoch Lite⾃带配电系统，可以为100W以\n' +
        '内的直流取样⽔泵提供稳定的供电⽽不需要搭配额外的电⽓控制系统。Epoch Lite还配备了微型空\n' +
        '调系统和独特的IP65防⽔设计，整机可以在室内或室外等复杂环境条件下稳定使⽤。监测系统搭载\n' +
        '了⽆试剂的LOC在线监测分析仪，可以同时⾼频率监测有机物、氨氮、pH和⽔温4个重要⽔质参\n' +
        '数。',
      advantage: ['壁挂式安装，⽆需搭建基础，在墙壁、护栏或⽴杆上均可以安装',
        'LOC芯⽚中内置pH和温度传感器，可以将铵离⼦浓度精确转换成氨氮浓度',
        '可以采⽤220V市电或24V直流电供电，也可以接⼊到太阳能供电系统中',
        '可以在低温和⾼热的室外环境中使⽤⽽⽆需搭建恒温设施',
        '搭配数据平台使⽤，可以在桌⾯端和微信端获取数据，可远程监测设备运⾏状况'],
      settings: ['应急或农村污⽔处理设施进出⽔监测',
        '⽆⼈船或浮标⼤⾯积连续监测',
        '污⽔排放⼝监测',
        '⾬⽔或污⽔泵站',
        '地表⽔细⽹格⽔质监测⽹点'],
      info:[
        {
          name: 'DIMENSION 尺⼨',
          data: '760*450*200',
        },{
          name: 'WEIGHT 重量',
          data: '20 Kg',
        },{
          name: 'POWER 供电',
          data: '220 VAC',
        },{
          name: 'PROTECTION 防护',
          data: 'IP54',
        },{
          name: 'Waste 废液',
          data: '0'
        },{
          name: 'COMMUNICATION 通讯',
          data: '4G-Cat1/NB-IoT'
        },{
          name: '氨氮 | COD | pH | ⽔温',
          data: '2-4'
        }
      ],

    },
    {
      id:'EpochPro',
      name: 'Epoch Pro',
      img: EpoachPro,
      link: EpoachProPdf,
      description: 'Epcoh Pro是毫厘科技开发的迄今为⽌全世界体积最⼩的全参数微型⽔质监测站，可以采⽤国标⽅法监测包括\n' +
        'COD、氨氮、总磷（磷酸盐）和总氮（硝酸盐）在内的9个主要⽔质参数。Epoch Pro采⽤了4层结构模块化设计，\n' +
        '可根据⽤户实际需求进⾏灵活的现场搭建：\n' +
        '• A层搭载了4种核⼼LOC模块、完整的取⽔配电系统、太阳能控制系统和空调恒温系统；\n' +
        '• B层搭载了常规5参数监测系统可以根据使⽤需要灵活安装；\n' +
        '• C层电池层配备了200AH的动⼒电池可以配合太阳能电池板⽀持整个系统离⽹使⽤；\n' +
        '• D层为底座层⽤于现场快速安装和接管。\n' +
        'Epoch Pro空调恒温系统、低能耗设计、独特的IP65防⽔设计可以保证整机可以在⽆供电和严苛⽓候条件的室外稳\n' +
        '定使⽤。',
      advantage: ['采⽤多层设计，可以现场快速搭建安装投⼊使⽤，占地⾯积仅需0.25平⽅⽶',
        '采⽤国标推荐⽅法监测多⼤9种重要的⽔质参数',
        '可以采⽤220V市电或24V直流电供电，也可以接⼊到太阳能供电系统中',
        '可以在低温和⾼热的室外环境中使⽤⽽⽆需搭建保护设施',
        '搭配数据平台使⽤，可以在桌⾯端和微信端获取数据，可远程监测设备运⾏状况'],
      settings: ['重要河道断⾯监测或湖泊流域等地表⽔监测',
        '企业污⽔处理设施终端管理或报警',
        '污⽔或⾬⽔排放⼝',
        '污⽔或⾬⽔泵站',],
      info:[
        {
          name: 'DIMENSION 尺⼨',
          data: '760*450*200',
        },{
          name: 'WEIGHT 重量',
          data: '20 Kg',
        },{
          name: 'POWER 供电',
          data: '220 VAC',
        },{
          name: 'PROTECTION 防护',
          data: 'IP54',
        },{
          name: 'Waste 废液',
          data: '≤1 mL'
        },{
          name: 'COMMUNICATION 通讯',
          data: '4G-Cat1/NB-IoT'
        },{
          name: '氨氮 | COD | 硝酸盐 | 总磷 | 常规5参数',
          data: '5-9'
        }
      ],

    },
    {
      id:'EpochPi',
      name: 'Epoch Pi',
      img: EpoachPi,
      link: EpoachPiPdf,
      description: 'Epcoh Pi是毫厘科技开发的全探头型实时在线监测系统，⾮常适合排放⼜，管⽹内部，检查井等⽆\n' +
        '法使⽤全尺⼨设备的场景。Epoch Pi可以灵活组合多达7种⽔质参数的探头并⽤于精度要求不⾼的\n' +
        '管理监测场景。Epoch Pi可以通过超低功耗的NB-IoT与服务器进⾏超低延时的通讯来试试上传数\n' +
        '据，低功耗设计可以保证系统在⽆供电情况下仅仅依靠电池供电就可以⼯作半年以上，特别适合管\n' +
        '⽹等⽆法供电的或者防爆要求等级⾼的场合。',
      advantage: ['全探头设计，⽆需使⽤试剂，可以安装在管径⼤于DN400的管道中',
        '低功耗NB-IoT设计，⽆需供电可以使⽤半年以上',
        '可以采⽤220V市电或24V直流电供电，也可以接⼊到太阳能供电系统中',
        '系统具备IP68防⽔性能，可以在完全淹没状态下使⽤',
        '搭配数据平台使⽤，可以在桌⾯端和微信端获取数据，可远程监测设备运⾏状况'],
      settings: ['⽆法安装全尺⼨设备的河渠或者排⼝',
        '污⽔或⾬⽔管⽹',
        '污⽔排放⼝监测',
        '污⽔或⾬⽔排放⼝'],
      info:[
        {
          name: 'DIMENSION 尺⼨',
          data: '760*450*200',
        },{
          name: 'WEIGHT 重量',
          data: '20 Kg',
        },{
          name: 'POWER 供电',
          data: '220 VAC',
        },{
          name: 'PROTECTION 防护',
          data: 'IP68',
        },{
          name: 'Waste 废液',
          data: '0'
        },{
          name: 'COMMUNICATION 通讯',
          data: 'NB-IoT'
        },{
          name: '氨氮 | COD | 常规5参数',
          data: '5-7'
        }
      ],

    },

  ]
}
