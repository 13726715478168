import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import  '../../styles/GridsStyle.css';



const Grids = () => {
  return (
    <Container fluid className='visual-grid-container-fixed'>
      <Row className='visual-grid-wrapper'>
        <Col>
          <Row className="visual-grid">
            <Col className="col-6 col-md-2 visual-grid__vertical" />
            <Col className="d-none col-md-4 d-md-block d-xl-none visual-grid__vertical" />
            <Col className="d-none col-md-4 d-md-block d-xl-none visual-grid__vertical" />
            <Col className="col-6 col-xl-2 d-md-none d-xl-block visual-grid__vertical" />
            <Col className="d-none col-xl-2 d-md-none d-xl-block visual-grid__vertical" />
            <Col className="d-none col-xl-2 d-md-none d-xl-block visual-grid__vertical" />
            <Col className="d-none col-xl-2 d-xl-block visual-grid__vertical" />
            <Col className="d-none col-md-2 d-md-block visual-grid__vertical" />
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Grids
