import styled from 'styled-components';
import {Link as LinkS} from 'react-scroll';
import {Link} from 'react-router-dom';

export const ButtonScroll = styled(LinkS)`
  width: 228px;
  height: 62px;
  color: #fff;
  font-size: 16px;
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  border-radius: 50px;
  border: 2px solid #fff;
  background: none;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  &:hover {
    transition: all .2s ease-in-out;
    background: #fff;
    color: #3b3939;
    text-decoration: none;
  }

`
export const ButtonLink = styled(Link)`
  width: 228px;
  height: 62px;
  color: #fff;
  font-size: 16px;
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  border-radius: 50px;
  border: 2px solid #fff;
  background: none;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  &:hover {
    transition: all .2s ease-in-out;
    background: #fff;
    color: #3b3939;
    text-decoration: none;
  }
  
  &.disabled {
    opacity: .5;
  }
  &.disabled:hover {
    background: inherit;
    color: #fff;
    text-decoration: none;
  }
`

export const BlueButton = styled.a`
  width: 228px;
  height: 62px;
  color: #1D98A9;
  font-size: 16px;
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  border-radius: 50px;
  border: 2px solid #1D98A9;
  background: none;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  &:hover {
    transition: all .2s ease-in-out;
    background: #fff;
    opacity: .5;
    text-decoration: none;
  }

  &.disabled {
    opacity: .5;
  }
  &.disabled:hover {
    background: inherit;
    color: #fff;
    text-decoration: none;
  }
`
