import React from 'react'
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SideBtnWrap,
  SidebarLink,
  HtmlLink,
  SidebarMenu,
} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} toggle={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink className='regular-text' to='/monitor' onClick={toggle}>在线监测</SidebarLink>
          <SidebarLink className='regular-text' to='/station' onClick={toggle}>整机微站</SidebarLink>
          <SidebarLink className='regular-text' to='/case' onClick={toggle}>行业案例</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <HtmlLink className='regular-text' href='/dashboard/login' target="_blank" onClick={toggle}>登入</HtmlLink>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
