import styled from 'styled-components';
import {Container, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom'

const MonitorWrapper = styled(Container)`
  
`
const MonitorCategoryWrapper = styled(Col)`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
`

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width:1200px) {
  }
`
const InfoWrapper = styled(Link)`
  width: 30%;
  white-space: normal;
  text-align: left;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  h3 {
    padding-bottom: 10px;
    margin-top: 20px;
  }
  p {
    text-decoration: none;
    font-family: 'PingFangSC-Thin', 'NotoSansSC-Thin', monospace;
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    color: #333333;
    letter-spacing: 0;
    text-align: left;
    line-height: 34px;
  }
  &:hover {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    h3::after {
      width: 40px;
    }
  }
  @media screen and (min-width:1200px) {
    width: 22%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    p {
      font-size: 16px;
      line-height: 30px;
    }
  }
`

const Image = styled.img`
  width: 100%;
`

export {MonitorWrapper, CategoryItem, MonitorCategoryWrapper, ContentWrapper, InfoWrapper, Image}
