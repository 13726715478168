import React from 'react';
import {LOCObj} from './Data';
import {
  Caption,
  Slide,
  ContentWrapper,
  CarouselWrapper,
  SlideImage,
  SlideVideo
} from './LOCSectionElements'
import { Col, Row, Container } from 'react-bootstrap';
import '../../styles/BoostrapStyleOverride.css';
import {BlueButton} from '../ButtonElement';
import '../../styles/BoostrapStyleOverride.css';

const LOCSection = () => {
  const items = LOCObj.items
  return (
    <Container fluid className='visual-grid-container light-background'>
      <Row fluid className='visual-grid-wrapper'>
        <Col sm={12} md={2} className="d-none d-md-block"/>
        <CarouselWrapper className='col' data-interval={false}>
          {[...Array(items.length)].map((_, index) =>
            <Slide key={index} className='slide' pause='hover' interval={5000}>
              <ContentWrapper>
                <Caption>
                  <h3>{items[index].name}</h3>
                  <p>{items[index].description}</p>
                  {
                    items[index].link ?
                      <BlueButton href={items[index].link}>了解更多</BlueButton>
                      :
                      null
                  }
                </Caption>
                {
                  index == 0 ?

                    <SlideVideo autoPlay controls>
                      <source src={items[index].img}  type="video/mp4" />
                    </SlideVideo>
                    :
                    <SlideImage fluid
                                src={items[index].img}
                                alt={items[index].name}
                    />
                }
              </ContentWrapper>
            </Slide>
          )}
        </CarouselWrapper>
        <Col sm={12} md={2} className="d-none d-md-block"/>
      </Row>
    </Container>
  )
}

export default LOCSection
