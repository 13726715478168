import React from 'react';
import StationInfo from '../components/StationInfo'

const StationPage = () => {
  return (
    <>
    <StationInfo />
    </>
  )
}

export default StationPage
