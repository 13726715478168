import styled from 'styled-components';

export const InfoContainer = styled.div`
  background: #F4F6F8;
  display: flex;
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    height: auto;
  }
`

export const InfoWrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: -60px;
  padding: 0 24px;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TextWrapper = styled.div`
  padding-top: 0;
  max-width: 1500px;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Heading = styled.h1`
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  font-size: 44px;
  color: #333333;
  letter-spacing: 2px;
  line-height: 62px;
  margin-top: 40px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
`

export const BulletWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  max-width: 1500px;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  width: 30%;
  @media screen and (max-width: 768px) {
    width: 100%;

  }
`
export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

export const Icon = styled.img`
  width: 20px;
`

export const Subtitle = styled.p`
  font-family: 'PingFangSC-Medium', 'NotoSansSC-Medium', monospace;
  font-size: 20px;
  color: #1D98A9;
  letter-spacing: 1px;
  margin-top: 10px;
`

export const Description = styled.p`
  font-family: 'PingFangSC-Thin', 'NotoSansSC-Thin', monospace;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  color: #333333;
  letter-spacing: 0;
  text-align: left;
  line-height: 34px;
  @media screen and (max-width: 1400px) {
    line-height: 28px;
    font-size: 16px;
  }
`

export const ImgWrapper = styled.div`
  max-width: 1500px;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`
