import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import AboutPage from './pages/about';
import Navbar from './components/Navbar';
import MonitorPage from './pages/monitor';
import MonitorDetail from './pages/monitorDetail'
import StationPage from './pages/station'
import CasePage from './pages/case'
import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Router>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Switch>
        <Route path={'/'} component={Home} exact />
        <Route path={'/about'} component={AboutPage} exact />
        <Route path={'/monitor'} component={MonitorPage} exact />
        <Route path={'/monitorDetail/:id'} exact component={MonitorDetail}/>}/>
        <Route path={'/station'} exact component={StationPage}/>}/>
        <Route path={'/case'} exact component={CasePage}/>}/>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
