import styled from 'styled-components';
import {Container, Col} from 'react-bootstrap';

export const SoftwareContainer = styled(Container)`
  display: flex;
  height: 100vh;
  &.container-fluid.visual-grid-container {
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`
export const LeftSection = styled(Col)`
  background: #fff;
  padding: 32px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`
export const RightSection = styled(Col)`
  background: #F4F6F8;
  padding: 100px 0;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

export const ContentWrapper = styled.div`
  margin: auto;
`
export const TextWrapper = styled.div`
  margin: auto;
  padding-top: 32px;
  h3 {
    line-height: 50px;
    margin-bottom: 10px                                                                                                                                                                                             ;
  }
  @media screen and (max-width: 768px) {
    padding: 32px;
  }
`

export const Image = styled.img`
  width: 100%;
`
