import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const ClientsContainer = styled(Container)`
  display: flex;
  padding: 80px 32px;
  height: 100vh;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 50px 0;
  }
`
export const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
`
export const TextWrapper = styled.div`
  margin: auto;
  p {
    max-width: unset;
    margin-top: 24px;
    margin-bottom: 50px;
  }
`
export const Image = styled.img`
  width: 80%;
  max-width: 1413px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
