import React from 'react';
import {MonitorObj} from './Data'
import {
  MonitorWrapper,
  MonitorCategoryWrapper,
  CategoryItem,
  ContentWrapper,
  InfoWrapper,
  Image
} from './MonitorElements';
import { Col, Row } from 'react-bootstrap';


function MonitorInfo (props) {
  const categories = MonitorObj.categories

  const getPath = (array, index) => {
    let path
    array.name === '传统探头' ? path = '/monitor' : path = '/MonitorDetail/' + array.items[index].id
    return path
  }
  return (
    <MonitorWrapper fluid className='visual-grid-container light-background'>
      <Row fluid className='visual-grid-wrapper'>
      <Col sm={12} md={2} className="d-none d-md-block"/>
      <MonitorCategoryWrapper fluid className='visual-grid-wrapper' md={8} xs={12}>
      {[...Array(categories.length)].map((_,i) =>
          <CategoryItem>
            <h3 className='medium-subtitle'>{categories[i].name}</h3>
            <ContentWrapper>
            {[...Array(categories[i].items.length)].map((_,k) =>
              <>
                <InfoWrapper to={getPath(categories[i],k)} params={{ id: categories[i].items[k].id}}>
                <Image src={categories[i].items[k].img} />
                <h3 className='small-subtitle blue-text yellow-underline'>{categories[i].items[k].name}</h3>
                <p>{categories[i].items[k].description}</p>
              </InfoWrapper>
              </>
            )}
            </ContentWrapper>
          </CategoryItem>
      )}
      </MonitorCategoryWrapper>
      <Col sm={12} md={2} className="d-none d-md-block"/>
      </Row>
    </MonitorWrapper>
  )
}

export default MonitorInfo
