import organism from '../../images/monitors/有机物分析仪.png';
import NH3N from '../../images/monitors/氨氮分析仪.png';
import NO3 from '../../images/monitors/硝酸盐（总氮）分析仪.png';
import NH3NSecond from '../../images/monitors/氨氮分析仪（水杨酸）.png';
import COD from '../../images/monitors/COD分析仪.png';
import TN from '../../images/monitors/总磷分析仪.png';
import Cl from '../../images/monitors/余氯分析仪.png';
import DO from '../../images/monitors/溶解氧DO探头.jpg';
import PH from '../../images/monitors/pH探头.jpg';
import conductivity from '../../images/monitors/电导率EC探头.jpg';
import turbidity from '../../images/monitors/浊度Turb探头.jpg';

export const MonitorObj = {
  categories: [{
    name: '微试剂LOC分析仪',
    items: [
      {
        id: 'NH3NSecond',
        name: '氨氮分析仪',
        description: '基于⽔杨酸法LOC氨氮分析仪，⽤于地表⽔或污⽔出⽔的氨氮连续在线监测',
        img: NH3NSecond,
      },{
        id: 'COD',
        name: '化学需氧量分析仪',
        description: '基于重铬酸钾氧化法法或⾼锰酸\n' +
          '盐法LOC化学需氧量(COD)分\n' +
          '析仪，⽤于地表⽔或污染源控制\n' +
          '连续在线监测',
        img: COD,
      },{
        id: 'TN',
        name: '总磷分析仪',
        description: '基于钼酸盐法法LOC总磷（磷酸\n' +
          '盐）分析仪，⽤于地表⽔或污⽔\n' +
          '出⽔的总磷连续在线监测',
        img: TN,
      },{
        id: 'Cl',
        name: '余氯分析仪',
        description: '基于DPD法LOC余氯分析仪，⽤\n' +
          '于⼆次供⽔或泳池⽔的低浓度余\n' +
          '氯⾼精度连续在线监测',
        img: Cl,
      }

    ]
  },{
    name: '零试剂LOC分析仪',
    items: [
      {
        id: 'organism',
        name: '有机物分析仪',
        description: '基于紫外吸收光谱，⽤于地表⽔\n' +
          '或污⽔出⽔的有机物连续在线监\n' +
          '测',
        img: organism,
      },{
        id: 'NH3N',
        name: '氨氮分析仪',
        description: '基于LOC架构并嵌⼊离⼦载体技\n' +
          '术，⽤于地表⽔或污⽔出⽔的氨\n' +
          '氮连续在线监测',
        img: NH3N,
      },{
        id: 'NO3',
        name: '硝酸盐(总氮)分析仪',
        description: '基于⾮⽓态光源紫外光谱和硝酸\n' +
          '盐选择分离技术，⽤于地表⽔或\n' +
          '污⽔出⽔的硝酸盐氮续在线监测',
        img: NO3,
      }]
  },{
    name: '传统探头',
    items: [
      {
        id: 'DO',
        name: '溶解氧探头',
        description: '采⽤荧光淬灭法的数字探头，⼯\n' +
          '业、市政或地表⽔中溶解氧的实\n' +
          '时监测',
        img: DO,
      },{
        id: 'pH',
        name: 'pH探头',
        description: '采⽤玻璃电极法的数字探头，⼯\n' +
          '业、市政或地表⽔中pH的实时监\n' +
          '测',
        img: PH,
      },{
        id: 'conductivity',
        name: '电导率探头',
        description: '采⽤四电极法或双电极的数字探\n' +
          '头，⼯业、市政或地表⽔中电导\n' +
          '率的实时监测',
        img: conductivity,
      },{
        id: 'turbidity',
        name: '浊度探头',
        description: '采⽤红外光谱法的数字探头，⼯\n' +
          '业、市政或地表⽔中浊度的实时\n' +
          '监测',
        img: turbidity,
      }
    ]
  }
  ]
}
