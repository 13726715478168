import kunshan1 from '../../images/case/kunshan/IMG_6702.png';
import kunshan2 from '../../images/case/kunshan/IMG_6705.jpg';
import kunshan3 from '../../images/case/kunshan/编组 25.png';
import wuxi1 from '../../images/case/wuxi/IMG_6414.jpeg';
import wuxi2 from '../../images/case/wuxi/IMG_6415.jpeg';
import wuxi3 from '../../images/case/wuxi/IMG_6416.jpeg';
import wuxi4 from '../../images/case/wuxi/IMG_6417.jpeg';
import beiluohe1 from '../../images/case/beiluohe/3a9fa8dd00a6fdc22f21a578715fbdd.jpeg';
import beiluohe2 from '../../images/case/beiluohe/lADPD3IrrzKvGn_NAmzNAdE_465_620.jpeg';
import beiluohe3 from '../../images/case/beiluohe/lADPGoGu9dKxp4LNBQDNA8A_960_1280.jpeg';
import beiluohe4 from '../../images/case/beiluohe/lADPGojJ8xw3_bLNBQDNA8A_960_1280.jpeg';
import beiluohe5 from '../../images/case/beiluohe/lADPGoU8dHd4J1PNBQDNA8A_960_1280.jpeg';
import coverImage from '../../images/case/IMG_6706.png'

export const CaseObj = {
  introduction: {
    img: coverImage,
    sectionOne: '毫厘科技的Epoch Lite整机微站可以为区域性河⽹管理提供了有⼒的数据保障。昆⼭细⽹格监测项⽬位于江苏省苏州市昆⼭市，毫厘科技与昆⼭市⽣态环境局通⼒合作，在10余条河流部署了20余台Epoch Lite微站⽤来连续监测这些河道中的氨氮，未来Epoch监测站将会扩展到昆⼭近百余条河流和点位。昆⼭位于⻓三⻆地区重点环境保护区域，毗邻阳澄湖和太湖等诸多敏感⽔域，城区河道的⽔质安全对保证整个昆⼭市的河⽹以及流域⽔质有重要作⽤。',
    sectionTwo: '城区的河道的主要污染物主要来⾃于⽣活污⽔，⻓期以来管⽹的混接和维护不善极易导致⽣活污⽔混⼊到⾬⽔管⽹并进⼊到河道中，导致河道⽔质污染恶化。⼤范围细⽹格的⽔质微站布点可以⻓期监测河道的污染物来源，⼀⽅⾯可以加强对排放源头的管理，另⼀⽅⾯可以通过⼈为⼲预的⽅式来管控污染物的扩散。毫厘科技的Epoch Lite微站为河⽹的氨氮污染物控制、污染物溯源以及综合管理提供了基础的数据⽀持。'

  },
  case: [
    {
      img: [kunshan1, kunshan2, kunshan3],
      name: '昆⼭城市河道细⽹格监测',
      info: [
        '微站数量：25台',
        '产品类型：Epoch Lite',
        '监测参数：氨氮、pH和⽔温'
      ]
    },{
      img: [wuxi1, wuxi2, wuxi3],
      name: '⽆锡市⽀流断⾯监测',
      info: [
        '微站数量：10台',
        '产品类型：Epoch Pro',
        '监测参数：氨氮、总磷、pH和⽔温',
        '案例简介：该项⽬主要⽤微站来监测乡镇地区的河道断⾯⽔质，为局部地区的河道⽔质管理，污染预警和区域污染防治管理提供数据⽀持。'
      ]
    },{
      img: [beiluohe1, beiluohe2, beiluohe3],
      name: '北洛河污染源监测',
      info: [
        '微站数量：15台',
        '产品类型：Epoch Pi',
        '监测参数：COD、氨氮、电导率、pH和⽔温',
        '案例简介：北洛河位于陕⻄省蒲城县，⻄北地区⽔⽹复杂、流量变化剧烈，常规微站安装困难。经过综合考虑，在此项⽬上使⽤了最⽅便部署的Epoch Pi在北洛河以及相关⽀流15个点位进⾏了⻓达6个⽉的⽔质监测，基本确定了流域的污染物来源，为河道的治理和管理提供了宝贵的数据。'
      ]
    }
  ]
}
