import {
  Image,
  Carousel,
} from 'react-bootstrap';
import styled from 'styled-components'

export const CarouselWrapper = styled(Carousel)`
  background: #fff;
  padding: 32px 0;
  height: 100vh;
`
export const Slide = styled(Carousel.Item)`
  align-items: center;
  min-height: 500px;
  position: relative;
  z-index: 0;
  background: #fff;
`
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 9;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Caption = styled(Carousel.Caption)`
  position: relative;
  left: unset;
  right: unset;
  bottom: unset;
  top: unset;
  width: 45%;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 2;
  }
  h3 {
    font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
    font-size: 44px;
    color: #333333;
    letter-spacing: 2px;
    text-align: left;
    @media screen and (max-width: 1200px) {
      font-size: 18px;
    }
  }
  p {
    font-family: 'PingFangSC-Thin', 'NotoSansSC-Thin', monospace;
    max-width: 440px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #333333;
    letter-spacing: 0;
    text-align: left;
    line-height: 34px;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }
`
export const SlideImage = styled(Image)`
  padding: 0;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 80%;
    order: 1;
  }
`

export const SlideVideo = styled.video`
  padding: 0;
  width: 50%;
  position: relative;
  z-index: 9;
  @media screen and (max-width: 768px) {
    width: 100%;
    order: 1;
  }
`
