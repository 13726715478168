import React from 'react';
import Grids from '../components/Grids';
import '../styles/TextStyle.css'
import CaseInfo from '../components/CaseInfo'
import HeroSection from '../components/HeroSection'
import img from '../images/heroImage-case.png'

function CasePage (props) {
  return (
    <div>
      <CaseInfo/>
    </div>
  )
}

export default CasePage
