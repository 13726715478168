export const AboutObj = {
  items: [
    {
      name: '关于毫厘科技',
      description1: '毫厘科技创建于2018年，是上海柏中观澈智能科技有限公司的注册品牌，致⼒于开发全球领先的芯⽚实验室（Lab-On-a-Chip, LOC）技术在线⽔质监测技术并⼴泛应⽤在环境监测、⼯业智能控制、智慧农业和家庭⽔质安全等场景下的⽔质⼤数据采集和数据应⽤。LOC技术可以将实验室的标准化学分析流程缩微到厘⽶尺度的微流控芯⽚上，仅需要微升级的样品就可以实现⼗余种重要⽔质参数的连续监测，真正做到了“滴⽔检测”。',
      description2: '毫厘科技的创新⽔质监测系统彻底颠覆了结构复杂的传统监测技术，⼤⼤降低了投资和运营成本，可以通过智能物联⽹系统（IOT）、5G通讯技术、边缘算⼒快速组⽹，使得⽔质数据采集变得极其简单，实现⼤规模的监测⽹络的快速部署。基于这种⾼密度的⽔质⼤数据收集，机器学习算法和理论⽔质模型可以为⼯业和市政客户提供多种维度系统解决⽅案、优化管理、控制系统⻛险和节约运营费⽤。',
    }
  ]

}
