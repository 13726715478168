export const SoftwareObj = {
  items: [
    {
      name: '智能物联⽹⽔质分析⽹络',
      description: '依托芯⽚实验室（Lab-on-a-chip）关键传感器技术和物联⽹边缘算⼒来实现多种重要⽔质参数的精确在线监测，可进⾏⼤范围细⽹格快速部署，构成智能感知⽹络的数据⼊⼝',
    }, {
      name: '⽔质⼤数据平台',
      description: '部署在云服务器上的物联⽹数据平台系统可以与数以万计的Epoch节点同时互联，凭借窄带物联⽹络的稳定，⼤范围和低延时的特性实现海量数据采集，再凭借云服务器的算⼒优势实现整个感知⽹络的数据挖掘和⾼效决策'
    }
  ]
}
