import styled from 'styled-components';

const ContentWrapper = styled.div`
  padding: 80px 50px;
  @media screen and (max-width: 960px) {
    padding: 32px;
    max-width: unset;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    max-width: unset;
  }
`

const SectionWrapper = styled.div`
  margin-bottom: 200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  @media screen and (max-width: 960px) {
    max-width: unset;
  }
  @media screen and (max-width: 768px) {
    max-width: unset;
    margin-bottom: 100px;
  }
`
const Headline = styled.p`
  width: 100%;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`
const TextWrapper = styled.div`
  width: 40%;
  p {
    max-width: unset;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    order: 2;
  }
`
const BulletWrapper = styled.ul`
  padding-left: 20px;
  padding-right: 0;
  li {
    margin: 0;
  }
`
const DataSection = styled.div`
  margin-top: 30px;
  
`
const DataItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  p {
    margin: 8px 0;
  }
`
const ImageWrapper = styled.div`
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 32px;
    order: 1;
  }
`
const Image = styled.img`
  width: 100%;
`
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

export {
  ContentWrapper,
  TextWrapper,
  BulletWrapper,
  Image,
  DataSection,
  DataItem,
  ImageWrapper,
  ButtonWrapper,
  SectionWrapper,
  Headline
}
