import React from 'react';
import {FaBars} from 'react-icons/fa';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoImg,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  HtmlLink,
} from './NavbarElements';
import Logo from '../../images/logo.svg'
import { Container, Row } from 'react-bootstrap'

const Navbar = ({toggle}) => {
  return (
    <Nav>
      <Container fluid className='visual-grid-container'>
        <Row className='visual-grid-wrapper'>
          <NavbarContainer className='col-12'>
            <NavLogo to='/' className="col-2 col-md-2 col-xs-4 col-lg-2">
              <NavLogoImg src={Logo} />
            </NavLogo>
            <MobileIcon className='col-8 col-md-8 col-xs-8' onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu className='col-8 col-md-8 col-lg-8'>
              <NavItem>
                <NavLinks
                  to='/'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={true}
                ><span>首页</span></NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/monitor'><span>在线监测</span></NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/station'><span>整机微站</span></NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/case'><span>行业案例</span></NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn className="col-2 col-md-2 col-lg-2">
              <HtmlLink href='/dashboard/login' target="_blank">登录</HtmlLink>
            </NavBtn>
          </NavbarContainer>
        </Row>
      </Container>
    </Nav>
  )
}

export default Navbar
