import React from 'react';
import {MonitorDetailObj} from './Data';
import {
  ContentWrapper,
  TextWrapper,
  BulletWrapper,
  Image,
  DataSection,
  DataItem,
  ImageWrapper,
  ButtonWrapper,
  TopSection
} from './MonitorDetailInfoElements';
import {ButtonLink} from '../ButtonElement';

function MonitorDetailInfo (props) {
  const id = props.match.params.id
  let contentData
  for (let item in MonitorDetailObj.items) {
    if (MonitorDetailObj.items[item].id === id) {
      contentData = MonitorDetailObj.items[item]
      break;
    }
  }
  return (
    <ContentWrapper>
      <TopSection>
        <DataSection>
          {[...Array(contentData.info.length)].map((_, index) =>
            <DataItem>
              <p className='light-text light-color'>{contentData.info[index].name}</p>
              <p className='light-text light-color'>{contentData.info[index].data}</p>
            </DataItem>
          )}
        </DataSection>
        <ImageWrapper>
          <Image src={contentData.img} />
        </ImageWrapper>
      </TopSection>
      <TextWrapper>
        <p className='thin-text light-color small-text'>{contentData.description}</p>
        <span className='thin-text light-color small-text'>优势</span>
        <BulletWrapper>
          {[...Array(contentData.advantage.length)].map((_, index) =>
            <li className='thin-text light-color small-text'>{contentData.advantage[index]}</li>
          )}
        </BulletWrapper>
        <span className='thin-text light-color small-text'>应用场景</span>
        <BulletWrapper>
        {[...Array(contentData.settings.length)].map((_, index) =>
          <li className='thin-text light-color small-text'>{contentData.settings[index]}</li>
        )}
        </BulletWrapper>
      </TextWrapper>
      {
        contentData.link ?
          <ButtonWrapper>
            {/*<ButtonLink className={'disabled'} >测试报告</ButtonLink>*/}
            <ButtonLink to={contentData.link} target="_blank">产品手册</ButtonLink>
          </ButtonWrapper>
          :
          null
      }
    </ContentWrapper>
  )
}

export default MonitorDetailInfo
