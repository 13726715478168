import img1 from '../../images/licenses/实用新型专利证书.png';
import img2 from '../../images/licenses/外观设计专利证书.png';
import img3 from '../../images/licenses/高新技术企业证书.png';

export const LicensesObj = {
  items: [{
    img: img1,
    name: '50+专利授权'
  }, {
    img: img2,
    name: '10+外观专利'
  }, {
    img: img3,
    name: '高新技术企业'
  }
  ]
}
