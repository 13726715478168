import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {ProductObj} from './Data'
import {
  Image,
  ContentWrapper,
  ProductContainer,
  TextWrapper,
  InfoWrapper
} from './ProductsElements';
import '../../styles/TextStyle.css'

const ProductSection = () => {
  const item1 = ProductObj.items[0]
  const item2 = ProductObj.items[1]
  return (
    <ProductContainer  fluid className='visual-grid-container'>
      <ContentWrapper>
        <Row className='visual-grid-wrapper'>
          <h3 className='medium-subtitle light-color col-12 text-center'>水质检测微站</h3>
          <InfoWrapper className='col'>
            <Image src={item1.img} />
            <TextWrapper>
              <h3 className='big-subtitle light-color blue-underline'>{item1.name}</h3>
              <p className='thin-text light-color'>{item1.description}</p>
            </TextWrapper>
          </InfoWrapper>
        </Row>
        <Row className='visual-grid-wrapper'>
          <InfoWrapper>
            <TextWrapper>
              <h3 className='big-subtitle light-color blue-underline'>{item2.name}</h3>
              <p className='thin-text light-color'>{item2.description}</p>
            </TextWrapper>
            <Image src={item2.img} />
          </InfoWrapper>
        </Row>
      </ContentWrapper>
    </ProductContainer>
  )
}

export default ProductSection
