import React from 'react';
import Grids from '../components/Grids';
import HeroSection from '../components/HeroSection';
import img from '../images/heroimage-device.jpeg';
import MonitorInfo from '../components/MonitorInfo';
import '../styles/TextStyle.css'

function MonitorPage (props) {
  return (
    <div>
      <Grids />
      <HeroSection hasContent={false} img={img}/>
      <MonitorInfo />
    </div>
  )
}

export default MonitorPage
