import React from 'react';
import Grids from '../components/Grids';
import HeroSection from '../components/HeroSection';
import AboutInfo from '../components/AboutInfo';
import img from '../images/heroImage-about.png'

function About (props) {
  return (
    <div>
      <Grids />
      <HeroSection hasContent={false} img={img}/>
      <AboutInfo />

    </div>
  )
}

export default About
