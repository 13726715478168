import styled from 'styled-components';
import {Container} from 'react-bootstrap'

export const ProductContainer = styled(Container)`
  background: #000;
`
export const ContentWrapper = styled.div`
  max-width: 1500px;
  width: 100%;
  h3 {
    margin-top: 30px;
  }
  @media screen and (max-width: 768px) {
    min-width: unset;
    width: 100%;
  }
  margin: auto;
`
export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0;
  height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`
export const TextWrapper = styled.div` {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 35%;
  
  h3 {
    line-height: 86px;
    margin: 0;
  }
  p {
    margin-top: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    
    h3 {
      display: flex;
    }
  }
}`

export const Image = styled.img`
  height: 85%;
  max-height: calc(100vh - 130px);
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 20px auto;
    order: 1;
  }
`
