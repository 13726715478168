import React from 'react'
import {
  SoftwareContainer,
  LeftSection,
  RightSection,
  ContentWrapper,
  TextWrapper,
  Image
  } from './SoftwareElements'
import img from '../../images/编组 9.png';
import {SoftwareObj} from './Data'

function SoftwareSection (props) {
  const items = SoftwareObj.items
  return (
    <SoftwareContainer fluid className='visual-grid-container'>
      <LeftSection className='col-md-6 col-sm-12 col-xs-12'>
          <Image src={img} />
      </LeftSection>
      <RightSection className='col-md-6 col-sm-12 col-xs-12'>
        {[...Array(items.length)].map((_, index) =>

        <TextWrapper>
          <h3 className='regular-text blue-underline'>{items[index].name}</h3>
          <p className='thin-text'>{items[index].description}</p>
        </TextWrapper>
        )}
        </RightSection>
    </SoftwareContainer>
  )
}

export default SoftwareSection
