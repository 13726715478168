import React from 'react'
import {
  LicensesContainer,
  ContentWrapper,
  ItemWrapper,
  Image
} from './LicensesElements';
import {LicensesObj} from './Data'
import { Col, Container, Row } from 'react-bootstrap'

function Licenses (props) {
  const items = LicensesObj.items
  return (
    <LicensesContainer fluid className='visual-grid-container'>
      <Row fluid className='visual-grid-wrapper'>
        <Col sm={12} md={2} className="d-none d-md-block"/>
        <ContentWrapper className='col-12'>
          {[...Array(items.length)].map((_, index) =>
            <ItemWrapper>
              <Image src={items[index].img} />
              <p className='thin-text text-center light-color'>{items[index].name}</p>
            </ItemWrapper>
          )}
        </ContentWrapper>
        <Col sm={12} md={2} className="d-none d-md-block"/>
      </Row>
    </LicensesContainer>
  )
}

export default Licenses
