import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const LicensesContainer = styled(Container)`
  display: flex;
  padding: 160px 100px 32px;
  background: #000;
  @media screen and (max-width: 768px) {
    padding: 32px;
  }
`
export const ContentWrapper = styled.div`
  margin: auto;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    min-width: unset;
    padding: 0;
  }
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 33%;
  p {
    max-width: unset;
    margin-top: 24px;
    text-align: center;
  }
`
export const Image = styled.img`
  width: 100%;
`
