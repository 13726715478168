import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import {AboutObj} from './Data';
import {
  TextWrapper,
  Description,
  DescriptionSection,
} from './AboutElements'

function AboutInfo (props) {
  const items = AboutObj.items
  return (
    <Container fluid className='visual-grid-container light-background'>
      <Row fluid className='visual-grid-wrapper'>
        <Col sm={12} md={2} className="d-none d-md-block"/>
        <TextWrapper md={8}>
          <h3 className='medium-subtitle'>{items[0].name}</h3>
          <Description>
            <DescriptionSection md={6} xs={12}>
              <p className='thin-text'>{items[0].description1}</p>
            </DescriptionSection>
            <DescriptionSection md={6} xs={12}>
              <p className='thin-text'>{items[0].description2}</p>
            </DescriptionSection>
          </Description>
        </TextWrapper>
        <Col sm={12} md={2} className="d-none d-md-block"/>
      </Row>
    </Container>
  )
}

export default AboutInfo
