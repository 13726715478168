import React from 'react';
import {Button} from '../ButtonElement'
import {
  InfoContainer,
  ImgWrapper,
  InfoRow,
  InfoWrapper,
  Heading,
  TextWrapper,
  Column,
  BulletWrapper,
  Description,
  Subtitle,
  Img,
  SubtitleWrapper,
  Icon
} from './ChipInfoElements';

const InfoSection = ({
                       lightBg,
                       className,
                       imgStart,
                       topLine,
                       lightText,
                       headLine,
                       darkText,
                       content,
                       img,
                       alt}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <ImgWrapper className={className}>
              <Img src={img} alt={alt}/>
            </ImgWrapper>
            <TextWrapper>
              <BulletWrapper>
                {[...Array(content.length)].map((_, index) =>
                  <Column>
                    <SubtitleWrapper>
                      <Icon src={content[index].icon}/>
                      <Subtitle>{content[index].subtitle}</Subtitle>
                    </SubtitleWrapper>
                    <Description>{content[index].description}</Description>
                  </Column>
                )}
              </BulletWrapper>
            </TextWrapper>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection
