import React, {useState} from 'react';
import {
  HeroContainer,
  HeroBg,
  ImgBg,
  HeroContent,
  HeroH1, HeroP,
  HeroBtnWrapper,
} from './HeroElements';
import HeroImage from '../../images/heroImage.png';
import {ButtonScroll} from '../ButtonElement'
import { Container, Row, Col } from 'react-bootstrap'
import {
  MobileIcon,
  NavbarContainer,
  NavBtn, NavBtnLink,
  NavItem,
  NavLinks,
  NavLogo,
  NavLogoImg,
  NavMenu
} from '../Navbar/NavbarElements'
import Logo from '../../images/logo.svg'
import { FaBars } from 'react-icons/fa';
import { scroller } from "react-scroll";

const HeroSection = ({hasContent, img}) => {
  const scrollToSection = () => {
    scroller.scrollTo("chip-info", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <HeroContainer>
      <HeroBg>
        <ImgBg src={img} />
      </HeroBg>
      {
        hasContent ?
          <Row className='visual-grid-wrapper'>
            <Col className="col-2 col-md-2 col-xs-0"/>
            <HeroContent className='col-8 col-md-8'>
              <HeroH1>
                芯片实验室
              </HeroH1>
              <HeroH1>
                颠覆水质监测
              </HeroH1>
              <HeroP>
                为环境监测和⼯业⽤户提供精准可靠的在线⽔质监测产品来快速获取⼤量⽔质数据
              </HeroP>
              <HeroBtnWrapper>
                <ButtonScroll onClick={scrollToSection}>
                  开始体验
                </ButtonScroll>
              </HeroBtnWrapper>
            </HeroContent>
            <Col className="col-2 col-md-2 col-xs-4"/>
          </Row>
          :
          null
      }
    </HeroContainer>
  )
}

export default HeroSection
