import React from 'react';
import {
  FooterContainer,
  TextWrapper,
  NavLinks,
  FooterText,
  TextLink,
  BeianWrapper,
  BeianIcon,
} from './FooterElements';
import img from '../../images/beian_icon.png'

function Footer (props) {
  const navItems = [
    {
      name: '关于毫厘',
      link: '/about'
    },{
      name: '加入毫厘',
      link: 'https://www.zhipin.com/gongsi/2fb5b3704b94549e1Hd729S5Ew~~.html'
    },{
      name: '公众号',
      link: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5MzAzNDUwNA==&scene=124#wechat_redirect'
    },{
      name: '领英',
      link: 'https://www.linkedin.com/company/haolitech/'
    },{
      name: '微博',
      link: 'http://weibo.cn/haolitech'
    },{
      name: '联系我们',
      link: '/'
    }]
  return (
    <FooterContainer fluid className='visual-grid-container'>
      <TextWrapper className='light-color'>
        <FooterText>Haoli©2021</FooterText>
        {[...Array(navItems.length)].map((_, index) =>
          <FooterText>
          <NavLinks href={navItems[index].link}>{navItems[index].name}</NavLinks>
          </FooterText>
        )}
        <BeianWrapper>
          <BeianIcon src={img}/>
          <TextLink href={'https://beian.miit.gov.cn/#/Integrated/index'}>沪ICP备19002190号-1</TextLink>
        </BeianWrapper>
      </TextWrapper>
    </FooterContainer>
  )
}

export default Footer
