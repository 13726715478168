import styled from 'styled-components';
import {Container} from 'react-bootstrap';


export const FooterContainer = styled(Container)`
  background: #000;
  display: flex;
  justify-content: center;
`
export const TextWrapper = styled.ul`
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0;
  }
`

export const FooterText = styled.li`
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  font-size: 14px;
  color: #BBBBBB;
  letter-spacing: 0;
  text-align: left;
  padding: 50px 10px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 5px 10px;
  }
`

export const NavLinks = styled.a`
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  white-space: normal;
  text-align: left;
  color: #BBBBBB;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    opacity: .5;
    text-decoration: none;
  }
`

export const BeianWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export const BeianIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`

export const TextLink = styled.a`
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  white-space: normal;
  color: #BBBBBB;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    opacity: .5;
    text-decoration: none;
  }
  
`
