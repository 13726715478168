import img from '../../images/109_main.jpeg';
import icon3 from '../../images/chipIcons/形状结合备份.png';
import icon2 from '../../images/chipIcons/椭圆形.png';
import icon1 from '../../images/chipIcons/编组.png';

export const ChipinfoObj = {
  className:'chip-info',
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  content: [{
    subtitle: '超高精度',
    icon: icon1,
    description: 'Lab-On-a-Chip核心芯片采用PDMS、COP或石英等多种材料，采用高精度飞秒激光(Femto Laser)和尖端精加工工艺制成，保证结果超高可重复性和分析精度',
  },{
    subtitle: '滴水检测',
    icon: icon2,
    description: 'Lab-On-a-Chip技术可以处理极端少量(微升级别)的样品来同时进行多项指标的检测，因此LOC也被成为微量全分析系统(micro total analysis system,TSA)',
  }, {
    subtitle: '长期使用',
    icon: icon3,
    description: '毫厘科技创造了可以重复使用的水质检测芯片，可以保证足够精度条件下使用5000次以上，不仅可以应对极端化学腐蚀性，还可以用于极端温度和压力条件',
  }],
  imgStart: false,
  img: img,
  alt: 'Lab-On-a-Chip',
  dark: false,
  primary: true,
  darkText: true,
}
