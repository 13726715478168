import styled from 'styled-components';
import {Container, Col} from 'react-bootstrap'

export const InfoWrapper = styled(Container)`
  z-index: 1;
  width: 100%;
  justify-content: center;
  background: #fff;
  max-width: unset;
  padding: 0;
  
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: -80px;
  z-index: 1;
  margin-bottom: 30px;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }
`
export const CaseWrapper = styled.div`
  padding: 140px 0;
  &:nth-child(even) {
    background: #F4F6F8;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 40px 0;
  }
`
export const CaseIntro = styled(Col)`
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`

export const TextWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`
export const Text = styled.p`
  width: 45%;
`

export const ImgWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`
export const ImgGroup = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ImageItem = styled.img`
  width: 30%;
  margin: 0 0 10px 0;
  padding-right: 0;
`
