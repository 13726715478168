import React from 'react'
import { ClientsContainer, ContentWrapper, TextWrapper, Image } from './ClientsElements'
import img from '../../images/编组 12.png'

function Clients (props) {
  return (
    <ClientsContainer fluid className='visual-grid-container light-background'>
      <ContentWrapper className='col-12'>
        <TextWrapper>
          <h3 className='medium-subtitle text-center'>重要客户</h3>
          <p className='thin-text text-center'>毫厘科技服务对象包括政府、⼯业企业、设备供应商、污⽔处理⼚等重要客户</p>
        </TextWrapper>
        <Image src={img} />
      </ContentWrapper>
    </ClientsContainer>
  )
}

export default Clients
