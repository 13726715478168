import img1 from '../../images/products/整机微站-lite.png';
import img2 from '../../images/products/图层 1.png';

export const ProductObj = {
  items: [
    {
      name: 'Epoch Lite',
      description: '体积最⼩的⽔质监测微站，⾃带微型空调系统，整体采⽤了⽆试剂的芯⽚实验室在线监测分析仪，可以同时⾼频率监测有机物、氨氮、pH和⽔温4个重要⽔质参数。⾮常适合部署在空间狭⼩的河道岸边、应急污⽔处理设施、⾬污排放⼜或泵站。',
      img: img1,
    },{
      name: 'Epoch Pro',
      description: '全球⾸台可以使⽤太能能驱动的9参数微站，⾃带空调系统，超紧凑尺⼨微站系统，可以⽀持包括COD、氨氮、总氮和总磷在内的9种关键参数在线监测，可以在使⽤条件严苛的野外及没有电⽹的场合快速部署组⽹。',
      img: img2,
    }
  ]
}
