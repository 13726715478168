import React from 'react';
import HeroSection from '../components/HeroSection';
import ChipSection from '../components/ChipSection';
import {ChipinfoObj} from '../components/ChipSection/Data';
import Grids from '../components/Grids';
import LOCSection from '../components/LOCSection';
import ProductSection from '../components/ProductSection'
import SoftwareSection from '../components/SoftwareSection'
import Clients from '../components/Clients'
import Licenses from '../components/Licenses'
import img from '../images/heroImage.png'
const Home = () => {
  return (
    <>
      <Grids />
      <HeroSection hasContent={true} img={img}/>
      <ChipSection {...ChipinfoObj}/>
      <LOCSection />
      <ProductSection />
      <SoftwareSection />
      <Clients />
      <Licenses />
    </>
  )
}

export default Home
